import { BehaviorSubject } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { iPagination } from '@ministrary/shared/interfaces/pagination.interface';
import { iUser } from '@ministrary/shared/interfaces/user.interface';
import { AuthService } from '@ministrary/shared/services/auth/auth.service';
import { LoadingService } from '@ministrary/shared/services/loading/loading.service';

import { ChildApi } from '../apis/child.api';
import { FamilyMembersApi } from '../apis/family-members.api';
import { FamilyApi } from '../apis/family.api';
import { eFamilyMemberStatus } from '../enums/family-member-status.enum';
import { iChild } from '../interfaces/child.interface';
import { iFamilyMember } from '../interfaces/family-member.interface';
import { iFamily } from '../interfaces/family.interface';

@Injectable({
  providedIn: 'root'
})
export class FamilyService {
  private authService = inject(AuthService);
  private familyApi = inject(FamilyApi);
  private familyMembersApi = inject(FamilyMembersApi);
  private childApi = inject(ChildApi);
  private loadingService = inject(LoadingService);

  family$ = new BehaviorSubject<iFamily | undefined>(undefined);
  familyMembers$ = new BehaviorSubject<iFamilyMember[] | undefined>(undefined);
  children$ = new BehaviorSubject<iChild[] | undefined>(undefined);

  get familyId() {
    return this.family$.value?.id;
  }

  loadingFamily: boolean = false;

  async load(force?: boolean) {
    if (this.loadingFamily) return;

    if (this.family$.value && !force) return this.family$.value;

    this.loadingFamily = true;
    let activeFamily = await this.familyMembersApi.getActiveFamilyByUserId();
    if (!activeFamily) activeFamily = await this.create();

    const family = await this.loadById(activeFamily.id);
    this.loadingFamily = false;

    this.setFamily(family as unknown as iFamily);

    return family;
  }

  async search(search?: string, searchWithoutChurch?: boolean) {
    return this.familyApi.search(search, searchWithoutChurch);
  }

  async loadById(familyId: string) {
    return this.familyApi.loadExpandedById(familyId);
  }

  async loadInById(familyId: string[], pagination: iPagination = { pageIndex: 1, pageSize: 10 }) {
    const { pageIndex, pageSize } = pagination || {};
    return this.familyApi.loadInById(familyId).range((pageIndex - 1) * pageSize, pageIndex * pageSize - 1);
  }

  resetFamily() {
    this.setFamily();
    this.setFamilyMembers();
    this.setChildren();
  }

  setFamily(family?: iFamily) {
    this.family$.next(family);
  }

  setFamilyMembers(familyMembers?: iFamilyMember[]) {
    this.familyMembers$.next(familyMembers);
  }

  setChildren(children?: iChild[]) {
    this.children$.next(children);
  }

  async create(user?: iUser) {
    const currentUser = user || this.authService.currentUser;

    const lastName = this.getUserLastName(currentUser);
    const { data, error } = await this.familyApi.insert({ name: lastName, owner: currentUser.id }).select().limit(1).single();
    if (error) throw Error('Erro ao criar família.');

    const familyData = data as unknown as iFamily;
    await this.familyMembersApi.insert({ family_id: familyData.id!, user_id: currentUser.id, status: eFamilyMemberStatus.ACCEPTED });

    return familyData;
  }

  async update(name: string, familyId: string) {
    const { error } = await this.familyApi.update({ name }).eq('id', familyId).select();
    if (error) throw Error('Erro ao atualizar família.');

    return this.load();
    // return this.load(true);
  }

  async dropFamily() {
    if (!this.familyId) return;

    await this.childApi.inactiveById(this.familyId, 'family_id');
    await this.familyMembersApi.inactiveById(this.familyId, 'family_id');
    await this.familyApi.inactiveById(this.familyId);
  }

  private getUserLastName(user: iUser): string {
    return user?.full_name?.split(' ').slice(-1)[0] || user.full_name!;
  }
}
