import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BaseApi } from '@ministrary/shared/apis/base.api';
import { ChurchService } from '@ministrary/shared/services/church/church.service';

import { iFamily } from '../interfaces/family.interface';

const FAMILY_SELECT = '*, family_members(*, users!user_id(*)), child(*)';
@Injectable({
  providedIn: 'root'
})
export class FamilyApi extends BaseApi<iFamily> {
  private churchService = inject(ChurchService);

  constructor() {
    super('family', environment.apiUrl);
  }

  search(search?: string, searchWithoutChurch?: boolean) {
    return this.supabase
      .rpc('search_family_by_church', {
        search_text: search,
        church_id_text: searchWithoutChurch ? null : this.churchService.selectedChurch()?.id
      })
      .limit(100);
  }

  loadInById(familyId: string[]) {
    return this.select(FAMILY_SELECT).in('id', familyId).eq('child.active', true).eq('family_members.active', true);
  }

  async loadExpandedById(familyId: string) {
    const { data: family } = await this.select(FAMILY_SELECT).eq('id', familyId).eq('child.active', true).eq('family_members.active', true).single();
    return family as unknown as iFamily;
  }

  loadAllExpanded() {
    return this.select(FAMILY_SELECT).eq('child.active', true).eq('family_members.active', true);
  }
}
